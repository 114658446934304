.orders-history-order-product {
  font-size: $orders-history-order-product-font-size;
  @each $property, $value in $orders-history-order-product-margins {
    margin-#{$property}: $value;
  }

  & + & {
    margin-top: $orders-history-order-product__plus-margin-top;
  }

  .info {
    display: flex;
    @each $property, $value in $orders-history-order-product-info-paddings {
      padding-#{$property}: $value;
    }

    .name {
      flex-grow: 1;
      @each $property, $value in $orders-history-order-product-name-paddings {
        padding-#{$property}: $value;
      }

      .details {
        @each $property, $value in $orders-history-order-product-name-details-paddings {
          padding-#{$property}: $value;
        }
      }
    }

    .status {
      .name {
        font-weight: $orders-history-order-product-status-name-font-weight;
        padding: 0;
      }

      .control {
        text-align: $orders-history-order-product-status-control-text-align;

        .btn-control {
          outline: none !important;
          border: none !important;
          box-shadow: none !important;
          width: 100%;
          font-size: $orders-history-order-product-status-control-btn-control-font-size;
          text-align: $orders-history-order-product-status-control-btn-control-text-align;

          @include hover-focus-active() {
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
          }
        }
      }
    }
  }

  .request-cancel {
    @each $property, $value in $orders-history-order-product-request-cancel-paddings {
      padding-#{$property}: $value;
    }
  }

  .tracking {
    @each $property, $value in $orders-history-order-product-tracking-paddings {
      padding-#{$property}: $value;
    }
    font-weight: $orders-history-order-product-tracking-font-weight;
  }

  .status {
    @each $property, $value in $orders-history-order-product-status-paddings {
      padding-#{$property}: $value;
    }
  }
}