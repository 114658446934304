.products-list-product .description {
  font-size: $products-product-description-font-size;

  .text {
    overflow: hidden;
    font-size: $products-list-product-description-font-size;
    font-weight: $products-list-product-description-font-weight;
    padding-top: $products-list-product-description-padding-top;
    line-height: $products-list-product-description-line-height;
    color: $products-list-product-description-color;

    &.truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .link {
    font-size: $products-list-product-description-link-font-size !important;
    line-height: $products-list-product-description-link-line-height !important;
  }
}