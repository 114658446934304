.products-page {

  .estimated-delivery-date {
    font-style: $products-page-estimated-delivery-date-font-style;
    font-size: $products-page-estimated-delivery-date-font-size;
    padding-bottom: $products-page-estimated-delivery-date-padding-bottom;
  }

  .note {
    font-style: $products-page-note-font-style;
    font-size: $products-page-note-font-size;
    padding-bottom: $products-page-note-padding-bottom;
  }
}