// .orders-history-order-product
$orders-history-order-product-font-size: 90%;
$orders-history-order-product-margins: (
        "top": 0,
        "right": $spacer,
        "bottom":0,
        "left":0
);

// .orders-history-order-product + .orders-history-order-product
$orders-history-order-product__plus-margin-top: $spacer;

// .orders-history-order-product .info
$orders-history-order-product-info-paddings: (
        "top": $spacer,
        "right": 0,
        "bottom":$spacer * 0.5,
        "left":0
);

// .orders-history-order-product .name
$orders-history-order-product-name-paddings: (
        "top": 0,
        "right": $spacer,
        "bottom":0,
        "left":0
);

// .orders-history-order-product .name .details
$orders-history-order-product-name-details-paddings: (
        "top": 0,
        "right": $spacer,
        "bottom":0,
        "left":0
);

// .orders-history-order-product .status .name
$orders-history-order-product-status-name-font-weight: bold;

// .orders-history-order-product .status .control
$orders-history-order-product-status-control-text-align: right;

// .orders-history-order-product .status .control .btn-control
$orders-history-order-product-status-control-btn-control-font-size: 12px;
$orders-history-order-product-status-control-btn-control-text-align: right;

// .orders-history-order-product .request-cancel
$orders-history-order-product-request-cancel-paddings: (
  "left": $spacer
);

// .orders-history-order-product .tracking
$orders-history-order-product-tracking-paddings: (
        "left": $spacer
);
$orders-history-order-product-tracking-font-weight: bold;

// .orders-history-order-product .status
$orders-history-order-product-status-paddings: (
        "left": $spacer
);