//.btn
$btn-height: 40px;
$btn-line-height: 40px;
$btn-text-transform: none;
$btn-letter-spacing: normal;
$btn-border-radius: $btn-height / 2;

$btn-paddings: (
        "top":0,
        "right":24px,
        "bottom": 0,
        "left":24px,
);

//.btn.btn-primary
$btn-primary-color: $white;
$btn-primary-background: $primary;
//.btn::hover
$btn-primary-hover-background: $primary;
$btn-primary-hover-color: $btn-primary-color;

//.btn.btn-outline-primary
$btn-outline-primary-text-transform: uppercase;
$btn-outline-primary-letter-spacing: 1px;
$btn-outline-primary-background-color: transparent;
$btn-outline-primary-color: $app-color-primary-light-1;
$btn-outline-primary-border: 1px solid $app-color-primary-light-1;
//.btn.btn-outline-primary::hover
$btn-outline-primary-hover-color: $app-color-white;
$btn-outline-primary-hover-border: 1.5px solid $app-color-primary;
$btn-outline-primary-hover-background: $app-color-primary;

//.btn.btn-sm
$btn-sm-height: 35px;
$btn-sm-padding-x: 16px;
$btn-sm-line-height: 35px;
$btn-sm-paddings: (
        "top":0,
        "right":16px,
        "bottom":0,
        "left":16px,
);

// .btn-swicher
$btn-switcher-size: 30px;
$btn-switcher-line-height: 28px;
$btn-switcher-border-radius: 50%;
$btn-switcher-background: transparent;
$btn-switcher-border: 1px solid $secondary;