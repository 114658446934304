/**
 * DXC colors schema
 */

$app-color-primary: #5f259f;
$app-color-tone: #3e037d;
$app-color-primary-light-1: #7229c0;
$app-color-primary-tint: #E9E0F7;
$app-color-primary-light-3: #F5F5FF;

$app-color-white: #fff;
$app-color-black: #1f2020;
$app-color-gray-dark: #737373;

$app-color-warning: #ffc107;
$app-color-danger: #dc3545;

$app-background-gradient: linear-gradient(90deg, #671fa5 0.41%, #671fa5 129.85%);